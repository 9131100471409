import Image from "next/image";
import classnames from "classnames";
import { motion } from "framer-motion";

const MeasuredMan = () => {
  return (
    <section className="flex w-screen max-w-screen-xl flex-col items-start gap-8 px-6 sm:px-12">
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full text-center font-semibold text-base-content/30 lg:text-left",
          "text-3xl md:text-4xl",
        )}
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent ">
          Who do you want to be
        </span>{" "}
        when you grow up?
      </motion.h2>
      <div className="mt-8 grid w-full grid-cols-1 gap-16 lg:mt-0 lg:grid-cols-2 lg:gap-8">
        <div className="flex justify-center">
          <ExampleCard
            title="The Measured Man"
            headingColor="text-green-600 dark:text-green-400"
            attributes={[
              "Healthy joints",
              "Active lifestyle",
              "Youthful energy",
              "Sharp mind",
              "Positive outlook",
              "Independent",
              "Community involvement",
            ]}
            vitalityScore={95}
            vitalityStatus="optimal"
            imageSrc="/images/home/measured-man.png"
            imageAlt="Measured Man"
            borderColor="border-green-600 dark:border-green-400"
          />
        </div>
        <div className="flex justify-center">
          <ExampleCard
            title="Average 80 Year Old"
            headingColor="text-base-content/60"
            attributes={[
              "Arthritis",
              "Limited mobility",
              "Low energy",
              "Mental decline",
              "Anxiety",
              "Dependent",
              "Financial burden",
            ]}
            vitalityScore={41}
            vitalityStatus="poor"
            imageSrc="/images/home/average-80-year-old.png"
            imageAlt="Average 80 Year Old"
            borderColor="border-base-300"
          />
        </div>
      </div>
    </section>
  );
};

interface ExampleCardProps {
  title: string;
  headingColor: string;
  attributes: string[];
  vitalityScore: number;
  vitalityStatus:
    | "optimal"
    | "excellent"
    | "good"
    | "okay"
    | "poor"
    | "critical";
  imageSrc: string;
  imageAlt: string;
  borderColor: string;
}

const ExampleCard = ({
  title,
  attributes,
  vitalityScore,
  vitalityStatus,
  imageSrc,
  imageAlt,
  borderColor,
  headingColor,
}: ExampleCardProps) => {
  return (
    <div className="flex w-full flex-col items-start justify-center gap-4 lg:max-w-[600px]">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        viewport={{ once: true }}
        className={classnames(
          "relative flex w-full flex-row gap-4 rounded-2xl border p-8",
          "justify-end sm:justify-around lg:justify-between",
          borderColor,
        )}
      >
        <Image
          src={imageSrc}
          alt={imageAlt}
          width={200}
          height={300}
          className={classnames(
            "absolute p-2 sm:relative sm:p-4",
            "-bottom-6 -left-16 sm:-bottom-0 sm:-left-0",
            "h-[350px] w-[233px] sm:h-[300px] sm:w-[200px]",
          )}
        />
        <div className="flex w-1/2 flex-col justify-between gap-6 md:w-1/3 lg:w-1/2">
          <VitalityScore score={vitalityScore} status={vitalityStatus} />
          <CardAttributes attributes={attributes} />
        </div>
      </motion.div>
      <motion.p
        initial={{ opacity: 0, x: 40 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        viewport={{ once: true }}
        className={classnames("pl-4 text-base font-normal", headingColor)}
      >
        {title}
      </motion.p>
    </div>
  );
};

interface CardAttributesProps {
  attributes: string[];
}

const CardAttributes = ({ attributes }: CardAttributesProps) => {
  return (
    <div className="flex flex-col gap-0.5">
      {attributes.map((attribute) => (
        <p className="font-normal text-base-content/40" key={attribute}>
          {attribute}
        </p>
      ))}
    </div>
  );
};

interface VitalityScoreProps {
  score: number;
  status: "optimal" | "excellent" | "good" | "okay" | "poor" | "critical";
}

const statusColors = {
  optimal:
    "bg-violet-600/10 dark:bg-violet-400/10 text-violet-400 dark:text-violet-600",
  excellent:
    "bg-blue-600/10 dark:bg-blue-400/10 text-blue-400 dark:text-blue-600",
  good: "bg-green-600/10 dark:bg-green-400/10 text-green-400 dark:text-green-600",
  okay: "bg-yellow-600/10 dark:bg-yellow-400/10 text-yellow-400 dark:text-yellow-600",
  poor: "bg-red-600/10 dark:bg-red-400/10 text-red-400 dark:text-red-600",
  critical: "bg-red-600/10 dark:bg-red-400/10 text-red-400 dark:text-red-600",
};

const VitalityScore = ({ score, status }: VitalityScoreProps) => {
  return (
    <div className="flex flex-col gap-0 rounded-lg bg-gradient-to-r from-base-200 to-base-200/10 px-3 py-2">
      <p className="font-semibold text-base-content/30">Vitality</p>
      <div className="flex flex-row items-center gap-2">
        <p className="text-2xl font-extrabold">{score}</p>
        <p
          className={classnames(
            "h-fit rounded-sm px-1 text-sm font-semibold",
            statusColors[status],
          )}
        >
          {status}
        </p>
      </div>
    </div>
  );
};

export default MeasuredMan;
