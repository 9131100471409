import type { NextPage } from "next";
import classnames from "classnames";

import EvaluationSources from "~/components/home-page/evaluation-sources";
import GetYourScore from "~/components/home-page/get-your-score";
import Hero from "~/components/home-page/hero-section";
import MainCta from "~/components/home-page/main-cta";
import MeasuredMan from "~/components/home-page/measured-man";
import Solution from "~/components/home-page/solution";
import SolutionTwo from "~/components/home-page/solution-two";
import StopGuessing from "~/components/home-page/stop-guessing";
import TrackItImproveIt from "~/components/home-page/track-it-improve-it";
import PageLayout from "~/components/layout/PageLayout";
import BgGrid from "../components/home-page/bg-grid";

const Home: NextPage = () => {
  return (
    <>
      <PageLayout
        seo={{
          title: "Home",
          description: "Track your health with one simple score.",
        }}
      >
        <BgGrid />
        <div
          className={classnames(
            "z-10 flex flex-col items-center justify-center",
            "gap-40  md:gap-64",
            "py-28 sm:py-32 md:py-40",
          )}
        >
          <Hero />
          <StopGuessing />
          <Solution />
          <SolutionTwo />
          <MeasuredMan />
          <GetYourScore />
          <TrackItImproveIt />
          <EvaluationSources />
          <MainCta />
        </div>
      </PageLayout>
    </>
  );
};

export default Home;
