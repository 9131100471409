import { useState } from "react";
import Image from "next/image";
import classnames from "classnames";
import { motion } from "framer-motion";

const StopGuessing = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <section className="relative flex w-screen max-w-screen-xl flex-col items-center justify-center gap-6 md:gap-12">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full text-left font-semibold text-base-content/40",
          "text-2xl sm:text-3xl md:text-4xl",
          "px-6 sm:px-12",
        )}
      >
        <span className=" bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent sm:whitespace-nowrap ">
          Stop guessing, start measuring.
        </span>{" "}
        The best plan is the one that gets the best results.
      </motion.h2>
      <div className="relative w-full overflow-x-scroll pl-6 pr-0 sm:pl-12 sm:pr-12">
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          className="align-start flex w-[800px] flex-row items-start sm:w-full"
        >
          <Image
            src="/images/home/choices-dark.png"
            alt="preventative health routine calendar and holistic protocol stack"
            width={1280}
            height={496.67}
            sizes="80vh"
            quality={90}
            className={classnames(
              "hidden w-full dark:block",
              "transition-all duration-300 ease-in-out",
              "pr-6 sm:pr-0",
              loaded ? "scale-100 opacity-100" : "scale-105 opacity-0",
            )}
            onLoad={() => setLoaded(true)}
            draggable={false}
          />
          <Image
            src="/images/home/choices-light.png"
            alt="preventative health routine calendar and holistic protocol stack"
            width={1280}
            height={496.67}
            sizes="80vh"
            quality={90}
            className={classnames(
              "block w-full dark:hidden",
              "transition-all duration-300 ease-in-out",
              "pr-6 sm:pr-0",
              loaded ? "scale-100 opacity-100" : "scale-105 opacity-0",
            )}
            onLoad={() => setLoaded(true)}
            draggable={false}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default StopGuessing;
