import Image from "next/image";
import Link from "next/link";
import {
  faArrowUpRight,
  faNewspaper,
} from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";

import Button from "../ui-elements/Button";
import WaitlistDialog from "../ui-elements/WaitlistDialog";

const MainCta = () => {
  return (
    <section
      className={classnames(
        "relative flex w-full flex-col items-center justify-center px-6 sm:px-12",
      )}
    >
      <div
        className={classnames(
          "relative flex w-full flex-col items-center justify-center",
          "gap-12 overflow-hidden rounded-3xl border-[1px] border-base-200 py-32",
          "bg-base-100/20 backdrop-blur-md",
        )}
      >
        <Image
          src="/images/home/vital-gradient-ball.png"
          alt="preventative health routine calendar and holistic protocol stack"
          className="absolute z-0 object-cover dark:hidden"
          fill
          sizes="(max-width: 1200px) 90vw, 2000px"
        />
        <Image
          src="/images/home/vital-gradient-ball-dark.png"
          alt="preventative health routine calendar and holistic protocol stack"
          className="absolute z-0 hidden object-cover dark:block"
          fill
          sizes="(max-width: 1200px) 90vw, 2000px"
        />
        <div className="light z-10 flex flex-col items-center justify-center gap-3 px-6 text-center text-base-content md:gap-2">
          <h2 className="bg-gradient-to-r from-base-100/100 to-base-100/80 bg-clip-text text-3xl font-bold text-transparent md:text-4xl">
            Unleash your potential
          </h2>
          <p className="text-xl font-light text-base-100 opacity-60 md:text-xl">
            Sign up for a free account to{" "}
            <span className="whitespace-nowrap font-light">get started.</span>
          </p>
        </div>
        <div className="dark z-10 flex gap-4">
          <Link href="/blog">
            <Button variant={"outline"} rightIcon={faNewspaper}>
              Learn more
            </Button>
          </Link>
          <WaitlistDialog>
            <Button variant={"solid"} rightIcon={faArrowUpRight}>
              Sign up
            </Button>
          </WaitlistDialog>
        </div>
      </div>
    </section>
  );
};

export default MainCta;
