import React, { useEffect, useState } from "react";

const BgGrid: React.FC = () => {
  const [gridDimensions, setGridDimensions] = useState({ cols: 8, rows: 8 });

  useEffect(() => {
    const updateGridDimensions = () => {
      const aspectRatio = window.innerWidth / window.innerHeight;
      const cols = Math.round(Math.sqrt(256 * aspectRatio));
      const rows = Math.round(256 / cols);
      setGridDimensions({ cols, rows });
    };

    updateGridDimensions();
    window.addEventListener("resize", updateGridDimensions);
    return () => window.removeEventListener("resize", updateGridDimensions);
  }, []);

  const centerX = Math.floor(gridDimensions.cols / 2);
  const centerY = Math.floor(gridDimensions.rows / 2);
  const maxDistance = Math.sqrt(centerX * centerX + centerY * centerY);

  return (
    <div
      className="pointer-events-none fixed left-0 top-0 z-0 h-full w-full"
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${gridDimensions.cols}, 1fr)`,
        gridTemplateRows: `repeat(${gridDimensions.rows}, 1fr)`,
      }}
    >
      {Array.from({ length: gridDimensions.cols * gridDimensions.rows }).map(
        (_, index) => {
          const x = index % gridDimensions.cols;
          const y = Math.floor(index / gridDimensions.cols);
          const distance = Math.sqrt(
            Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2),
          );
          const opacity = 1 - distance / maxDistance;
          return <Cell key={index} opacity={opacity} />;
        },
      )}
    </div>
  );
};

const Cell: React.FC<{ opacity: number }> = ({ opacity }) => {
  return (
    <div
      className="border-b border-r border-dotted opacity-[12%]"
      style={{
        borderColor: `rgba(var(--color-base-content), ${opacity})`,
      }}
    />
  );
};

export default BgGrid;
