import { useState } from "react";
import Image from "next/image";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { motion } from "framer-motion";

const Solution = () => {
  return (
    <section
      className={classnames(
        "flex w-screen max-w-screen-xl flex-col items-start gap-6 md:gap-12",
      )}
    >
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        viewport={{ once: true }}
        className="px-6 text-2xl font-semibold text-base-content/40 sm:px-12 sm:text-3xl md:text-4xl"
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent ">
          Know what works for{" "}
          <span className="font-semibold text-base-content !underline">
            you
          </span>
        </span>{" "}
      </motion.h2>
      <div className="custom-scrollbar w-screen overflow-x-auto px-6 pb-4 sm:px-12">
        <div className="flex w-max flex-row items-start gap-4 pr-6 sm:gap-6 sm:pr-12 md:gap-[30px]">
          <ConsolidateData />
          <EvaluateMetrics />
          <ActionableInsights />
        </div>
      </div>
    </section>
  );
};

const ConsolidateData = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      viewport={{ once: true }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="flex w-[375px] flex-col items-start gap-2 md:gap-4"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className="relative w-full dark:hidden"
      >
        <Image
          src="/images/home/consolidate-light-active.png"
          alt="consolidate health data"
          width={372}
          height={227}
          className={classnames(
            "absolute w-full animate-fade",
            isHovered ? "block" : "hidden",
          )}
        />
        <Image
          src="/images/home/consolidate-light.png"
          alt="consolidate health data"
          width={372}
          height={227}
          className={"w-full animate-fade"}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className="relative hidden w-full dark:block"
      >
        <Image
          src="/images/home/consolidate-dark-active.png"
          alt="consolidate health data"
          width={372}
          height={227}
          className={classnames(
            "absolute w-full animate-fade",
            isHovered ? "block" : "hidden",
          )}
        />
        <Image
          src="/images/home/consolidate-dark.png"
          alt="consolidate health data"
          width={372}
          height={227}
          className={classnames("w-full  animate-fade ")}
        />
      </motion.div>
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full pl-4 text-left font-normal text-base-content/30",
          "text-xl md:text-2xl",
        )}
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-normal text-transparent ">
          Consolidate
        </span>{" "}
        health data
      </motion.h2>
    </motion.div>
  );
};

const EvaluateMetrics = () => {
  return (
    <motion.div
      className="flex w-[375px]  flex-col items-start gap-2 md:gap-4"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      viewport={{ once: true }}
    >
      <motion.div className="flex h-[229px] w-full flex-col items-center justify-center gap-6 rounded-3xl bg-base-200">
        <MetricLabel
          emoji="🦴"
          name="Bone Density"
          evaluation="poor"
          evaluationColor={EvaluationColors.poor}
        />
        <MetricLabel
          emoji="🏋️‍♂️"
          name="Max Leg Press"
          evaluation="good"
          evaluationColor={EvaluationColors.good}
        />
        <MetricLabel
          emoji="🫀"
          name="Resting Heart Rate"
          evaluation="excellent"
          evaluationColor={EvaluationColors.excellent}
        />
        <MetricLabel
          emoji="🫁"
          name="VO2 Max"
          evaluation="okay"
          evaluationColor={EvaluationColors.okay}
        />
      </motion.div>
      <motion.h2
        className={classnames(
          "w-full pl-4 text-left font-normal text-base-content/30",
          "text-xl md:text-2xl",
        )}
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-normal text-transparent ">
          Evaluate
        </span>{" "}
        every metric
      </motion.h2>
    </motion.div>
  );
};

interface MetricLabelProps {
  emoji: string;
  name: string;
  evaluation: string;
  evaluationColor: string[];
}

const EvaluationColors = {
  poor: ["bg-red-600/10", "text-red-600"],
  good: ["bg-green-600/10", "text-green-600"],
  excellent: ["bg-blue-600/10", "text-blue-600"],
  okay: ["bg-yellow-600/10", "text-yellow-600"],
};

const MetricLabel = ({
  emoji,
  name,
  evaluation,
  evaluationColor,
}: MetricLabelProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="flex w-full flex-row items-center justify-between gap-3 px-8"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="flex flex-row items-center gap-2">
        <p className="text w-4">{emoji}</p>
        <p className="text-sm font-normal">{name}</p>
      </div>
      <div
        className={classnames(
          "rounded-md px-1 py-0.5 leading-none duration-150",
          isHovered ? evaluationColor[0] : "bg-base-300",
        )}
      >
        <p
          className={classnames(
            "text-sm font-normal duration-150",
            isHovered ? evaluationColor[1] : "text-base-placeholder",
          )}
        >
          {evaluation}
        </p>
      </div>
    </motion.div>
  );
};

const ActionableInsights = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.4 }}
      viewport={{ once: true }}
      className="flex w-[375px]  flex-col items-start gap-2 md:gap-4"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="flex h-[229px] w-full flex-col gap-4 rounded-3xl bg-base-200 p-8">
        <div className="flex flex-row items-center justify-start gap-4 text-2xl font-semibold">
          <p className="font-extrabold text-base-content/50">74</p>
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-base-content/30"
          />
          <p
            className={classnames(
              "font-extrabold duration-150",
              isHovered ? "scale-110" : "scale-100",
            )}
          >
            86
          </p>
        </div>
        <div className="relative dark:hidden">
          <Image
            src="/images/home/line-light-active.svg"
            alt="arrow right"
            width={320}
            height={69}
            className={classnames(
              "absolute animate-fade duration-150",
              isHovered ? "block" : "hidden",
            )}
          />
          <Image
            src="/images/home/line-light.svg"
            alt="arrow right"
            width={320}
            height={69}
            className={"aboslute animate-fade"}
          />
        </div>
        <div className="relative hidden dark:block">
          <Image
            src="/images/home/line-dark-active.svg"
            alt="arrow right"
            width={320}
            height={69}
            className={classnames(
              "absolute animate-fade duration-150",
              isHovered ? "block" : "hidden",
            )}
          />
          <Image
            src="/images/home/line-dark.svg"
            alt="arrow right"
            width={320}
            height={69}
            className={"aboslute animate-fade"}
          />
        </div>
        <p className="text-xs text-base-content/50">
          Improving{" "}
          <span className="font-normal underline">cardio fitness</span> from{" "}
          <span
            className={classnames(
              "font-semibold duration-150",
              isHovered
                ? "text-yellow-600 dark:text-yellow-400"
                : "text-base-content/50",
            )}
          >
            okay
          </span>{" "}
          to{" "}
          <span
            className={classnames(
              "font-semibold duration-150",
              isHovered
                ? "text-blue-600 dark:text-blue-400"
                : "text-base-content/50",
            )}
          >
            excellent
          </span>{" "}
          would have the biggest impact on your vitality
        </p>
      </div>
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full pl-4 text-left font-normal text-base-content/30",
          "text-xl md:text-2xl",
        )}
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-normal text-transparent ">
          Get actionable insights
        </span>
      </motion.h2>
    </motion.div>
  );
};

export default Solution;
