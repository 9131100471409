import classnames from "classnames";
import { motion } from "framer-motion";

import MetricTooltip from "../ui-elements/tooltip";

interface Card {
  title: string;
  emoji: string;
  description: string;
}

const cards: Card[] = [
  {
    title: "Muscle Mass",
    emoji: "💪",
    description: "total weight of all the muscle tissue in your body",
  },
  {
    title: "Heart Rate Variability",
    emoji: "🫀",
    description:
      "the variation in time intervals between consecutive heartbeats",
  },
  {
    title: "Gut Microbiome Diversity",
    emoji: "🦠",
    description: "variety of bacteria species in your gut",
  },
  {
    title: "Visceral Fat Index",
    emoji: "🧍",
    description: "the amount of fat stored in organs",
  },
  {
    title: "Tryglicerides",
    emoji: "🩸",
    description: "the total amount of triglycerides stored in your fat cells",
  },
  {
    title: "Free Testosterone",
    emoji: "🦠",
    description:
      "the fraction of testosterone that is not bound to proteins in your blood",
  },
  {
    title: "Zinc",
    emoji: "🪨",
    description: "the amount of zinc in your blood",
  },
  {
    title: "Grip Strength",
    emoji: "✋",
    description:
      "the maximum grip force (in kg) that you can apply with your weaker hand",
  },
  {
    title: "Resting Heart Rate",
    emoji: "🫀",
    description: "your average heart rate when at rest",
  },
  {
    title: "VO2 Max",
    emoji: "🫁",
    description:
      "your ability to deliver oxygen to muscles during physical activity",
  },
  {
    title: "1RM Squat",
    emoji: "🏋️‍♂️",
    description:
      "the maximum added weight you can squat using proper form relative to to your bodyweight",
  },
  {
    title: "B12",
    emoji: "💧️",
    description: "how much B12 vitamin is in your blood",
  },
  {
    title: "C-Reactive Protein",
    emoji: "🧬",
    description: "a marker of inflammation",
  },
  {
    title: "Free T3",
    emoji: "🧪",
    description:
      "the amount of active form of thyroid hormone that is unbound and circulating in the blood",
  },
  {
    title: "Cortisol",
    emoji: "😰",
    description: "the amount of stress hormone in the body",
  },
  {
    title: "Heart Rate Recovery",
    emoji: "🧪",
    description:
      "how quickly your heart rate can drop minutes after reaching peak heart rate during exercise",
  },
  {
    title: "Avg. Sleep Duration",
    emoji: "💤",
    description: "average total sleep time for past seven nights",
  },
  {
    title: "Basal Metabolic Rate",
    emoji: "🔋",
    description: "your energy expenditure at rest",
  },
  {
    title: "Blood Glucose",
    emoji: "🩸",
    description: "sugar concentration in the blood",
  },
  {
    title: "Progesterone",
    emoji: "🧬",
    description: "how much progesterone is in your blood",
  },
  {
    title: "Vitamin D",
    emoji: "🧈",
    description: "how much Vitamin D is in your blood",
  },
  {
    title: "White Blood Cell Count",
    emoji: "🩸",
    description: "the number of white blood cells in your blood",
  },
  {
    title: "Omega-3 Fatty Acids",
    emoji: "🐟",
    description: "what percentage of your total fatty acids are Omega-3s",
  },
  {
    title: "Respiratory Rate",
    emoji: "🫁",
    description: "number of times you breath per minute",
  },
  {
    title: "Iron",
    emoji: "🪨",
    description: "the amount of iron in your blood",
  },
  {
    title: "Reaction Time",
    emoji: "💥",
    description: "the speed at which the body responds to stimuli",
  },
  {
    title: "Bone Density",
    emoji: "🦴",
    description: "the strength and mineral content of bones",
  },
  {
    title: "Body Fat %",
    emoji: "🧍",
    description:
      "total weight of all the fat on your body relative to the total weight of your body",
  },
  {
    title: "Subjective Mood Patterns",
    emoji: "😟",
    description: "personal perception of mood over time",
  },
  {
    title: "Calprotectin Levels",
    emoji: "💩",
    description: "a marker of gastrointestinal tract inflammation",
  },
  {
    title: "Pulse Pressure",
    emoji: "🫀",
    description:
      "an indicator of arterial stiffness measured by the difference between systolic and diastolic blood pressure ",
  },
  {
    title: "Sex Hormone-Binding Globulin",
    emoji: "🧬",
    description:
      "how much SHBG, a protein that binds to sex hormones, is in your blood",
  },
  {
    title: "Folate",
    emoji: "💧",
    description: "how much Folate (B9) is in your blood",
  },

  {
    title: "and more...",
    emoji: "➕",
    description:
      "download the app to see the full list of biomarkers you can track",
  },
];

const TrackItImproveIt = () => {
  return (
    <section className="flex w-screen max-w-screen-xl flex-col items-center justify-center gap-8 px-6 sm:gap-12 sm:px-12">
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "w-full text-left font-semibold text-base-content/30",
          "text-3xl md:text-4xl",
        )}
      >
        <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent">
          If you can track it
        </span>
        , you can improve it
      </motion.h2>
      <div
        className={classnames(
          "max-w-screen-xl",
          "flex flex-row flex-wrap",
          "-ml-4 gap-1 sm:gap-2 md:gap-4",
        )}
      >
        {cards.map(Card)}
      </div>
    </section>
  );
};

const Card = ({ emoji, title, description }: Card, i: number) => {
  return (
    <MetricTooltip
      content={description}
      key={title}
      classnames="bg-base-100/60 text-base-content"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.05 * i }}
        viewport={{ once: true }}
        className={classnames(
          "flex flex-row items-center justify-start gap-4 rounded-2xl",
          "w-fit px-4 py-2",
          "hover:bg-base-200",
        )}
      >
        <p className="w-3 text-sm md:text-base lg:text-lg">{emoji}</p>
        <h6
          className={classnames(
            "text-sm md:text-base lg:text-lg",
            "w-fit whitespace-nowrap",
          )}
        >
          {title}
        </h6>
      </motion.div>
    </MetricTooltip>
  );
};

export default TrackItImproveIt;
