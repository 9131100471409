import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";

const MetricTooltip = ({
  children,
  content,
  classnames,
}: {
  children: React.ReactNode;
  content: React.ReactNode;
  classnames?: string;
}) => {
  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className={classNames(
              "z-50 animate-fade rounded-md px-4 py-2 text-base font-light shadow-md backdrop-blur-sm duration-150",
              classnames,
            )}
            sideOffset={5}
            align="start"
            side="bottom"
          >
            {content}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default MetricTooltip;
