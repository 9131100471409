import { useState } from "react";
import Image from "next/image";
import {
  faArrowDownRight,
  faArrowUpRight,
  faBolt,
  faDumbbell,
  faShield,
  faSpa,
} from "@fortawesome/pro-solid-svg-icons";
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { motion } from "framer-motion";

const SolutionTwo = () => {
  return (
    <section className="flex w-screen max-w-screen-xl flex-col items-start gap-8 px-6 sm:px-12">
      <div className="flex flex-col items-start gap-2">
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          className={classnames(
            "w-full text-left font-semibold text-base-content/30",
            "text-2xl sm:text-3xl md:text-4xl",
          )}
        >
          <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent ">
            Keep it simple
          </span>{" "}
          and put your health into terms that make sense
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          viewport={{ once: true }}
          className="w-full text-left text-base text-base-content/30 sm:text-lg"
        >
          Vitality is broken down into four equally important and intuitive
          categories.
        </motion.p>
      </div>
      <div className="grid w-full grid-cols-2 gap-4 md:gap-6 lg:grid-cols-4 lg:gap-8">
        <CategoryCard
          title="Energy"
          icon={faBolt}
          score={91}
          change={3}
          delay={0.4}
          images={CategoryCardImages.energy}
        />
        <CategoryCard
          title="Ability"
          icon={faDumbbell}
          score={86}
          change={10}
          delay={0.5}
          images={CategoryCardImages.ability}
        />
        <CategoryCard
          title="Wellbeing"
          icon={faSpa}
          score={67}
          change={2}
          delay={0.6}
          images={CategoryCardImages.wellbeing}
        />
        <CategoryCard
          title="Resilience"
          icon={faShield}
          score={75}
          change={-5}
          delay={0.7}
          images={CategoryCardImages.resilience}
        />
      </div>
    </section>
  );
};

interface CategoryCardProps {
  title: string;
  icon: FontAwesomeIconProps["icon"];
  score: number;
  change: number;
  delay: number;
  images: (typeof CategoryCardImages)[keyof typeof CategoryCardImages];
}

const CategoryCardImages = {
  energy: {
    light: {
      active: "/images/home/energy-line-light-active.svg",
      inactive: "/images/home/energy-line-light.svg",
    },
    dark: {
      active: "/images/home/energy-line-dark-active.svg",
      inactive: "/images/home/energy-line-dark.svg",
    },
  },
  ability: {
    light: {
      active: "/images/home/ability-line-light-active.svg",
      inactive: "/images/home/ability-line-light.svg",
    },
    dark: {
      active: "/images/home/ability-line-dark-active.svg",
      inactive: "/images/home/ability-line-dark.svg",
    },
  },
  wellbeing: {
    light: {
      active: "/images/home/wellbeing-line-light-active.svg",
      inactive: "/images/home/wellbeing-line-light.svg",
    },
    dark: {
      active: "/images/home/wellbeing-line-dark-active.svg",
      inactive: "/images/home/wellbeing-line-dark.svg",
    },
  },
  resilience: {
    light: {
      active: "/images/home/resilience-line-light-active.svg",
      inactive: "/images/home/resilience-line-light.svg",
    },
    dark: {
      active: "/images/home/resilience-line-dark-active.svg",
      inactive: "/images/home/resilience-line-dark.svg",
    },
  },
};

const CategoryCard = ({
  title,
  icon,
  score,
  change,
  delay,
  images,
}: CategoryCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const direction = change > 0 ? "up" : "down";

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: delay }}
      viewport={{ once: true }}
      className="relative flex aspect-square w-full flex-col items-start justify-between gap-3 rounded-2xl bg-base-200 p-3 sm:p-6"
      onHoverStart={() => setIsHover(true)}
      onHoverEnd={() => setIsHover(false)}
    >
      <div className="flex flex-row items-center gap-2">
        <FontAwesomeIcon icon={icon} className="w-4 text-sm sm:text-base" />
        <p className="text-sm font-normal sm:text-base">{title}</p>
      </div>
      <div className="relative w-full dark:hidden">
        <Image
          src={images.light.active}
          alt="graph line"
          width={208}
          height={76}
          className={classnames(
            "absolute w-full duration-150",
            isHover ? "opacity-100" : "opacity-0",
          )}
        />
        <Image
          src={images.light.inactive}
          alt="graph line"
          width={208}
          height={76}
          className="w-full duration-150"
        />
      </div>
      <div className="relative hidden w-full dark:block">
        <Image
          src={images.dark.active}
          alt="graph line"
          width={208}
          height={76}
          className={classnames(
            "absolute w-full duration-150",
            isHover ? "opacity-100" : "opacity-0",
          )}
        />
        <Image
          src={images.dark.inactive}
          alt="graph line"
          width={208}
          height={76}
          className="w-full duration-150"
        />
      </div>
      <div className="align-center flex w-full flex-row justify-between">
        <p className="text-xl font-extrabold sm:text-3xl">{score}</p>
        <div
          className={classnames(
            "flex flex-row items-center gap-1 rounded-full bg-base-300 px-3 py-0 text-xs font-normal duration-150",
            isHover && direction === "up"
              ? "bg-green-500/10 text-green-600 dark:text-green-400"
              : isHover && direction === "down"
              ? "bg-red-500/10 text-red-600 dark:text-red-400"
              : "text-base-content/60",
          )}
        >
          <FontAwesomeIcon
            icon={direction === "up" ? faArrowUpRight : faArrowDownRight}
            className={classnames("w-2 text-xs")}
          />
          <p className={classnames("text-xs font-normal")}>
            {Math.abs(change)}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default SolutionTwo;
